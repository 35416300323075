import axios from 'axios'
/* eslint no-param-reassign: 0 */

const urlEvents = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/events` : `${process.env.VUE_APP_API_DEV}/events`

export default {
  namespaced: true,
  state: {
    eventsList: [],
    calendarOptions: [
      {
        color: 'info',
        label: 'Location',
      },
      {
        color: 'success',
        label: 'Étalonnage',
      },
      {
        color: 'warning',
        label: 'SAV',
      },
    ],
    selectedCalendars: ['Location', 'Étalonnage', 'SAV'],
  },
  getters: {},
  mutations: {
    HYDRATE_EVENTSLIST(state, data) {
      state.eventsList = data
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    findEvent(state, eventId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlEvents}/${eventId}`)
          .then(event => {
            resolve(event.data)
          })
          .catch(error => reject(error))
      })
    },
    findAllEvents(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlEvents}`)
          .then(event => {
            state.commit('HYDRATE_EVENTSLIST', event.data)
            resolve(event.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchEvents(state, calendar) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlEvents}?title=${calendar}`)
          .then(event => {
            const result = event.data.map(elem => {
              elem.start = elem.startDate
              elem.end = elem.endDate
              elem.name = elem.title
              elem.title = `${elem.device.name} - ${elem.device.enterprise}`
              return elem
            })
            resolve(result)
          })
          .catch(error => reject(error))
      })
    },
    createEvent(state, body) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlEvents}`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    updateEvent(state, { eventId, body }) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlEvents}/${eventId}`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    deleteEvent(state, eventId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .delete(`${urlEvents}/${eventId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
