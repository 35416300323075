import axios from 'axios'

const urlImpacts = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/impacts` : `${process.env.VUE_APP_API_DEV}/impacts`

const urlSites = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/sites` : `${process.env.VUE_APP_API_DEV}/sites`

export default {
  namespaced: true,
  state: {
    dataList: null,
  },
  getters: {},
  mutations: {
    HYDRATE_DATALIST(state, data) {
      state.dataList = data
    },
  },
  actions: {
    findAllGmax(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlImpacts}/gmax`)
          .then(response => {
            state.commit('HYDRATE_DATALIST', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    findSiteNumber() {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlSites}/count`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    // findAllDataByDevice(state, deviceId) {
    //   axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    //   axios.defaults.headers.post['Content-Type'] = 'application/json'
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(`${urlImpacts}/gmax`, { device: deviceId })
    //       .then(response => {
    //         resolve(response.data)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
