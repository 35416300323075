import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2'
import JsonExcel from 'vue-json-excel'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// VueSweetAlert
Vue.use(VueSweetalert2)

Vue.component('downloadExcel', JsonExcel)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

// Filters :

const displayDate = date => {
  if (date === null) {
    return null
  }
  return moment(date).locale('fr').format('L')
}

const displayDate2 = date => {
  if (date === null) {
    return null
  }
  return moment(date).locale('fr').format('LL')
}

const truncate = (text, length, clamp) => {
  const clamp1 = (clamp || '...')
  const node = document.createElement('div')
  node.innerHTML = text
  const content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp1 : content
}

Vue.filter('displayDate', displayDate)
Vue.filter('displayDate2', displayDate2)
Vue.filter('truncate', truncate)
