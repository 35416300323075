import axios from 'axios'

const urlFiles = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/files` : `${process.env.VUE_APP_API_DEV}/files`

export default {
  namespaced: true,
  getters: {},
  actions: {
    fetchFiles() {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlFiles}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchFile(state, data) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        if (data.manufacturingId) {
          axios
            .get(`${urlFiles}?manufacturingId=${data.manufacturingId}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error))
        }
        if (data.eventId) {
          axios
            .get(`${urlFiles}?eventId=${data.eventId}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error))
        }
        if (data.rentalId) {
          axios
            .get(`${urlFiles}?rentalId=${data.rentalId}`)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => reject(error))
        }
      })
    },
    addFile(state, data) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlFiles}/upload`, data)
          .then(response => {
            console.log('ok file send')
            resolve(response)
          })
          .catch(error => {
            console.log('reject file send')
            reject(error)
          })
      })
    },
    deleteFile(state, id) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .delete(`${urlFiles}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // updateFile(state, { id, data }) {
    // axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    // axios.defaults.headers.post['Content-Type'] = 'application/json'
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .put(`${urlFiles}/${id}`, data)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // downloadFile(state, id) {
    // axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    // axios.defaults.headers.post['Content-Type'] = 'application/json'
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`${urlFiles}/download/${id}`, { responseType: 'blob' })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
