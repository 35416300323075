import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import data from './data'
import device from './device'
import devicetype from './devicetype'
import event from './event'
import evolution from './evolution'
import file from './file'
import manufacturing from './manufacturing'
import release from './release'
import rental from './rental'
import serialnumber from './serialnumber'
import translation from './translation/index'
import update from './update'
import user from './user'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    data,
    device,
    devicetype,
    event,
    evolution,
    file,
    manufacturing,
    release,
    rental,
    serialnumber,
    translation,
    update,
    user,
    verticalMenu,
  },
  strict: process.env.DEV,
})
