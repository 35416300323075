export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/UsersList.vue'),
    meta: {
      pageTitle: 'Users',
    },
  },
  {
    path: '/users/:id',
    name: 'users-view',
    component: () => import('@/views/users/UsersView.vue'),
    meta: {
      pageTitle: 'Users-view',
    },
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: () => import('@/views/users/UsersCreate.vue'),
    meta: {
      pageTitle: 'Users-create',
    },
  },
  {
    path: '/users/update/:id',
    name: 'users-update',
    component: () => import('@/views/users/UsersUpdate.vue'),
    meta: {
      pageTitle: 'Users-update',
    },
  },
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/views/devices/DevicesList.vue'),
    meta: {
      pageTitle: 'Devices',
    },
  },
  {
    path: '/devices/:id',
    name: 'devices-view',
    component: () => import('@/views/devices/DevicesView.vue'),
    meta: {
      pageTitle: 'Devices-view',
    },
  },
  {
    path: '/devices/create',
    name: 'devices-create',
    component: () => import('@/views/devices/DevicesCreate.vue'),
    meta: {
      pageTitle: 'Devices-create',
    },
  },
  {
    path: '/devices/update/:id',
    name: 'devices-update',
    component: () => import('@/views/devices/DevicesUpdate.vue'),
    meta: {
      pageTitle: 'Devices-update',
    },
  },
  {
    path: '/device-types',
    name: 'device-types',
    component: () => import('@/views/devicetypes/DevicetypesList.vue'),
    meta: {
      pageTitle: 'Device-types',
    },
  },
  {
    path: '/device-types/:id',
    name: 'device-types-view',
    component: () => import('@/views/devicetypes/DevicetypesView.vue'),
    meta: {
      pageTitle: 'Device-types-view',
    },
  },
  {
    path: '/device-types/create',
    name: 'device-types-create',
    component: () => import('@/views/devicetypes/DevicetypesCreate.vue'),
    meta: {
      pageTitle: 'Device-types-create',
    },
  },
  {
    path: '/device-types/update/:id',
    name: 'device-types-update',
    component: () => import('@/views/devicetypes/DevicetypesUpdate.vue'),
    meta: {
      pageTitle: 'device-types-update',
    },
  },
  {
    path: '/releases',
    name: 'releases',
    component: () => import('@/views/releases/ReleasesList.vue'),
    meta: {
      pageTitle: 'Releases',
    },
  },
  {
    path: '/translations',
    name: 'translations',
    component: () => import('@/views/translations/TranslationsList.vue'),
    meta: {
      pageTitle: 'Translations',
    },
  },
  {
    path: '/updates',
    name: 'updates',
    component: () => import('@/views/update/UpdatesList.vue'),
    meta: {
      pageTitle: 'Updates',
    },
  },
  {
    path: '/manufacturings',
    name: 'manufacturings',
    component: () => import('@/views/manufacturing/ManufacturingList.vue'),
    meta: {
      pageTitle: 'Manufacturings',
    },
  },
  {
    path: '/manufacturings/create',
    name: 'manufacturings-create',
    component: () => import('@/views/manufacturing/ManufacturingNew.vue'),
    meta: {
      pageTitle: 'Manufacturings-create',
    },
  },
  {
    path: '/manufacturings/edit/:id',
    name: 'manufacturings-edit',
    component: () => import('@/views/manufacturing/ManufacturingEdit.vue'),
    meta: {
      pageTitle: 'Manufacturings-edit',
    },
  },
  {
    path: '/manufacturings/view/:id',
    name: 'manufacturings-view',
    component: () => import('@/views/manufacturing/ManufacturingView.vue'),
    meta: {
      pageTitle: 'Manufacturings-view',
    },
  },
  {
    path: '/serialnumbers',
    name: 'serialnumbers',
    component: () => import('@/views/serialnumber/SerialnumbersList.vue'),
    meta: {
      pageTitle: 'serialnumbers',
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/calendar/Calendar.vue'),
    meta: {
      pageTitle: 'calendar',
    },
  },
  {
    path: '/evolutions',
    name: 'evolutions',
    component: () => import('@/views/evolution/EvolutionsList.vue'),
    meta: {
      pageTitle: 'evolutions',
    },
  },
  {
    path: '/sav',
    name: 'sav',
    component: () => import('@/views/sav/SavList.vue'),
    meta: {
      pageTitle: 'sav',
    },
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import('@/views/location/LocationList.vue'),
    meta: {
      pageTitle: 'locations',
    },
  },
  {
    path: '/locations/:id',
    name: 'locations-view',
    component: () => import('@/views/location/LocationView.vue'),
    meta: {
      pageTitle: 'locations-view',
    },
  },
  {
    path: '/locations/create',
    name: 'locations-create',
    component: () => import('@/views/location/LocationNew.vue'),
    meta: {
      pageTitle: 'locations-create',
    },
  },
  {
    path: '/locations/edit/:id',
    name: 'locations-edit',
    component: () => import('@/views/location/LocationEdit.vue'),
    meta: {
      pageTitle: 'locations-edit',
    },
  },
]
