import axios from 'axios'

const urlUsers = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/users` : `${process.env.VUE_APP_API_DEV}/users`

const urlCredentials = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/credentials` : `${process.env.VUE_APP_API_DEV}/credentials`

export default {
  namespaced: true,
  state: {
    usersList: null,
  },
  getters: {},
  mutations: {
    HYDRATE_USERSLIST(state, data) {
      state.usersList = data.sort((a, b) => a.enterprise.localeCompare(b.enterprise))
    },
  },
  actions: {
    findAllUsers(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlUsers}`)
          .then(response => {
            state.commit('HYDRATE_USERSLIST', response.data.users)
            resolve(response.data.users)
          })
          .catch(error => reject(error))
      })
    },
    findUser(state, userId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlUsers}/${userId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    findCredentials(state, userId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlCredentials}/${userId}`)
          .then(response => {
            resolve(response.data.credentials[0])
          })
          .catch(error => reject(error))
      })
    },
    createUser(state, body) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlUsers}`, body)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createCredentials(state, body) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlCredentials}`, body)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateUser(state, { userId, body }) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlUsers}/${userId}`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    updateCredentials(state, { body }) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlCredentials}`, body)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteUser(state, userId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .delete(`${urlUsers}/${userId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
