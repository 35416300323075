import axios from 'axios'

const urlSerialnumbers = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/serialnumbers` : `${process.env.VUE_APP_API_DEV}/serialnumbers`

export default {
  namespaced: true,
  state: {
    serialnumbersList: null,
  },
  getters: {},
  mutations: {
    HYDRATE_SERIALNUMBERSLIST(state, data) {
      state.serialnumbersList = data
    },
  },
  actions: {
    findSerialnumber(state, serialId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlSerialnumbers}/${serialId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    findAllSerialnumbers(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlSerialnumbers}`)
          .then(response => {
            state.commit('HYDRATE_SERIALNUMBERSLIST', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    createSerialnumber(state, body) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlSerialnumbers}`, body)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateSerialnumber(state, { serialId, body }) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlSerialnumbers}/${serialId}`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    deleteSerialnumber(state, serialId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .delete(`${urlSerialnumbers}/${serialId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
