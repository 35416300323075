import axios from 'axios'
/* eslint no-param-reassign: 0 */

const urlRentals = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/rentals` : `${process.env.VUE_APP_API_DEV}/rentals`

export default {
  namespaced: true,
  state: {
    rentalsList: [],
  },
  getters: {},
  mutations: {
    HYDRATE_RENTALSLIST(state, data) {
      state.rentalsList = data
    },
  },
  actions: {
    findRental(state, rentalId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlRentals}/${rentalId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    findRentalByDevice(state, deviceId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlRentals}?deviceId=${deviceId}`)
          .then(response => {
            resolve(response.data[0])
          })
          .catch(error => reject(error))
      })
    },
    findAllRentals(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlRentals}`)
          .then(response => {
            state.commit('HYDRATE_RENTALSLIST', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    createRental(state, body) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlRentals}`, body)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateRental(state, { rentalId, body }) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlRentals}/${rentalId}`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    deleteRental(state, rentalId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .delete(`${urlRentals}/${rentalId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
