import axios from 'axios'

const urlEvolutions = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/evolutions` : `${process.env.VUE_APP_API_DEV}/evolutions`

export default {
  namespaced: true,
  state: {
    evolutionsList: null,
  },
  getters: {},
  mutations: {
    HYDRATE_EVOLUTIONSLIST(state, data) {
      state.evolutionsList = data
    },
  },
  actions: {
    findEvolution(state, evolId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlEvolutions}/${evolId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    findAllEvolutions(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlEvolutions}`)
          .then(response => {
            state.commit('HYDRATE_EVOLUTIONSLIST', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    createEvolution(state, body) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlEvolutions}`, body)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateEvolution(state, { evolId, body }) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlEvolutions}/${evolId}`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    deleteEvolution(state, evolId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .delete(`${urlEvolutions}/${evolId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
