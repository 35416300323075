import axios from 'axios'

const urlDevices = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/devices` : `${process.env.VUE_APP_API_DEV}/devices`

const urlSites = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/sites` : `${process.env.VUE_APP_API_DEV}/sites`

const urlUpdates = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/updates` : `${process.env.VUE_APP_API_DEV}/updates`

export default {
  namespaced: true,
  state: {
    devicesList: [],
    devicesFromUser: null,
    sitesListForSelectedDevice: null,
    sitesListForSelectedUser: null,
    updatesListForSelectedDevice: null,
  },
  getters: {},
  mutations: {
    HYDRATE_DEVICESLIST(state, data) {
      state.devicesList = data
    },
    HYDRATE_DEVICESFROMUSER(state, data) {
      state.devicesFromUser = data
    },
    HYDRATE_SITESLIST(state, data) {
      state.sitesListForSelectedDevice = data
    },
    HYDRATE_SITESLIST2(state, data) {
      state.sitesListForSelectedUser = data
    },
    HYDRATE_UPDATESLIST(state, data) {
      state.updatesListForSelectedDevice = data
    },
  },
  actions: {
    findAllDevices(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlDevices}`)
          .then(response => {
            state.commit('HYDRATE_DEVICESLIST', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    findDevice(state, deviceId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlDevices}/${deviceId}`)
          .then(device => {
            resolve(device.data)
          })
          .catch(error => reject(error))
      })
    },
    findSitesByDevice(state, deviceId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve2, reject2) => {
        axios
          .get(`${urlSites}?device_id=${deviceId}`)
          .then(sites => {
            resolve2(sites.data)
            state.commit('HYDRATE_SITESLIST', sites.data)
          })
          .catch(error => reject2(error))
      })
    },
    findDevicesByUser(state, userId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve2, reject2) => {
        axios
          .get(`${urlDevices}?user_id=${userId}`)
          .then(devices => {
            resolve2(devices.data)
          })
          .catch(error => reject2(error))
      })
    },
    findDevicesByConnectedUser(state, userId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve2, reject2) => {
        axios
          .get(`${urlDevices}?user_id=${userId}`)
          .then(devices => {
            state.commit('HYDRATE_DEVICESFROMUSER', devices.data)
            resolve2(devices.data)
          })
          .catch(error => reject2(error))
      })
    },
    findSitesByUser(state, userId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve2, reject2) => {
        axios
          .get(`${urlSites}?user_id=${userId}`)
          .then(sites => {
            resolve2(sites.data)
            state.commit('HYDRATE_SITESLIST2', sites.data)
          })
          .catch(error => reject2(error))
      })
    },
    findUpdatesByDevice(state, deviceId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve2, reject2) => {
        axios
          .get(`${urlUpdates}?device_id=${deviceId}`)
          .then(updates => {
            resolve2(updates.data)
            state.commit('HYDRATE_UPDATESLIST', updates.data)
          })
          .catch(error => reject2(error))
      })
    },
    createDevice(state, body) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlDevices}`, body)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateDevice(state, { deviceId, body }) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlDevices}/${deviceId}`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
