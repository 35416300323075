import axios from 'axios'

const urlTranslations = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/translations` : `${process.env.VUE_APP_API_DEV}/translations`

export default {
  namespaced: true,
  state: {
    translationsList: null,
  },
  getters: {},
  mutations: {
    HYDRATE_TRANSLATIONSLIST(state, data) {
      state.translationsList = data
    },
  },
  actions: {
    findAllTranslations(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlTranslations}`)
          .then(response => {
            state.commit('HYDRATE_TRANSLATIONSLIST', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    createTranslation(state, data) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlTranslations}/createorfind?lang=${data.lang}&name=${data.name}`, data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    deleteTranslation(state, translaId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .delete(`${urlTranslations}/${translaId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    updateTranslation(state, { translaId, body }) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlTranslations}/${translaId}`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
