import axios from 'axios'

const urlReleases = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/docker_software_releases` : `${process.env.VUE_APP_API_DEV}/docker_software_releases`

export default {
  namespaced: true,
  state: {
    releasesList: null,
  },
  getters: {},
  mutations: {
    HYDRATE_RELEASESLIST(state, data) {
      state.releasesList = data
    },
  },
  actions: {
    findAllReleases(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlReleases}`)
          .then(response => {
            state.commit('HYDRATE_RELEASESLIST', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
