import axios from 'axios'

const urlUpdates = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/updates` : `${process.env.VUE_APP_API_DEV}/updates`

export default {
  namespaced: true,
  state: {
    updatesList: null,
  },
  getters: {},
  mutations: {
    HYDRATE_UPDATESLIST(state, data) {
      state.updatesList = data
    },
  },
  actions: {
    findAllUpdates(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlUpdates}`)
          .then(response => {
            state.commit('HYDRATE_UPDATESLIST', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
