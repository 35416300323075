import axios from 'axios'

const urlDevicetypes = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/device_types` : `${process.env.VUE_APP_API_DEV}/device_types`

export default {
  namespaced: true,
  state: {
    devicetypesList: null,
  },
  getters: {},
  mutations: {
    HYDRATE_DEVICETYPESLIST(state, data) {
      if (data) {
        state.devicetypesList = data.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      }
    },
  },
  actions: {
    findAllDevicetypes(state) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlDevicetypes}`)
          .then(response => {
            state.commit('HYDRATE_DEVICETYPESLIST', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    findDevicetype(state, devicetypeId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .get(`${urlDevicetypes}/${devicetypeId}`)
          .then(devicetype => {
            resolve(devicetype.data)
          })
          .catch(error => reject(error))
      })
    },
    createDevicetype(state, body) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlDevicetypes}`, body)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteDevicetype(state, devicetypeId) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .delete(`${urlDevicetypes}/${devicetypeId}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    updateDevicetype(state, { devicetypeId, body }) {
      axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.post['Content-Type'] = 'application/json'
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlDevicetypes}/${devicetypeId}`, body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}
