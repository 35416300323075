import axios from 'axios'
/* eslint no-underscore-dangle: 0 */

const urlUsers = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}/users` : `${process.env.VUE_APP_API_DEV}/users`
const url = process.env.VUE_APP_NODE_ENV === 'production'
  ? `${process.env.VUE_APP_API_PROD}` : `${process.env.VUE_APP_API_DEV}`

export default {
  namespaced: true,
  state: {
    role: null,
  },
  getters: {},
  mutations: {
    SET_ROLE(state, val) {
      state.role = val
    },
  },
  actions: {
    register(state, userInfos) {
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlUsers}`, userInfos)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async login(state, userInfos) {
      axios.defaults.headers.common.Authorization = `apiKey ${userInfos.email}:${userInfos.password}`
      localStorage.removeItem('accessToken')
      localStorage.removeItem('username')
      localStorage.removeItem('password')
      localStorage.removeItem('permissions')
      localStorage.removeItem('userId')
      localStorage.removeItem('exp')
      return new Promise((resolve, reject) => {
        axios
          .get(`${url}/me`)
          .then(response => {
            const token = `apiKey ${userInfos.email}:${userInfos.password}`
            localStorage.setItem('accessToken', token)
            localStorage.setItem('username', userInfos.email)
            localStorage.setItem('password', userInfos.password)
            localStorage.setItem('permissions', response.data.scopes.join(','))
            localStorage.setItem('exp', Date.now())
            localStorage.setItem('userId', response.data.id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout() {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('username')
      localStorage.removeItem('password')
      localStorage.removeItem('permissions')
      localStorage.removeItem('exp')
      localStorage.removeItem('userId')
    },
    refetchConnect(state, decoded) {
      return new Promise(() => {
        state.commit('SET_ROLE', decoded.role)
      })
    },
    updateMe(state, { id, data }) {
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlUsers}/me/${id}`, data)
          .then(response => {
            resolve(response)
            localStorage.removeItem('accessToken')
            localStorage.setItem('accessToken', response.data.token)
          })
          .catch(error => {
            console.log('error updateMe', error)
            reject(error)
          })
      })
    },
    // Function to receive email with link to reset password form
    renewPassword(state, email) {
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
      return new Promise((resolve, reject) => {
        axios
          .post(`${urlUsers}/renew-password`, email)
          .catch(error => {
            console.log('error forgotPassword', error)
            reject(error)
          })
      })
    },
    // Function tu update new password
    updatePassword(state, { id, token, password }) {
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
      return new Promise((resolve, reject) => {
        axios
          .patch(`${urlUsers}/${id}/password`, { token, password })
          .then(response => resolve(response))
          .catch(error => {
            console.log('error forgotPassword', error)
            reject(error)
          })
      })
    },
    setRole(state, role) {
      state.commit('SET_ROLE', role)
    },
  },
}
