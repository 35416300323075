import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import apps from './routes/apps'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    ...apps,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const unprotectedRoutes = ['login', 'login-password', 'register', 'forgot-password', 'error-404']
router.beforeEach((to, from, next) => {
  // hydrate each checking
  let role = null
  const token = localStorage.getItem('accessToken')
  const exp = localStorage.getItem('exp')
  const permissions = localStorage.getItem('permissions')
  const userId = localStorage.getItem('userId')
  if (permissions && permissions.includes('super')) {
    role = 1
  } else if (permissions && !permissions.includes('super') && permissions.includes('admin')) {
    role = 2
  } else role = 3
  // unprotected route open
  if (unprotectedRoutes.indexOf(to.name) > -1) next()
  // go login after expiration time
  else if (token && exp < (Date.now() - 10800000)) {
    next({ name: 'login' })
  } else if (token) {
    store.dispatch('device/findAllDevices')
    store.dispatch('event/findAllEvents')
    store.dispatch('devicetype/findAllDevicetypes')
    store.dispatch('user/findAllUsers')
    store.dispatch('update/findAllUpdates')
    store.dispatch('release/findAllReleases')
    store.dispatch('translation/findAllTranslations')
    store.dispatch('serialnumber/findAllSerialnumbers')
    store.dispatch('rental/findAllRentals')
    if (role) {
      store.dispatch('auth/setRole', role)
    }
    if (role === 2) {
      store.dispatch('device/findDevicesByConnectedUser', userId)
    }
    next()
  } else next({ name: 'login' })
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
